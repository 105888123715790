<template>
  <div class="about-me">
    <div class="about-option">
      <div class="about-label">自我介绍</div>
      <div class="about-value">
        作者2014年就读于宁波大学科学技术学院的计算机专业，2018年毕业并迈入互联网行业。</br>现居浙江宁波，就职当地一家互联网公司，欢迎联系作者交流和咨询。</br>
        目前职责：负责开发团队的项目进度；关注技术走势；复杂业务提供技术解决方案；技术储备、选型和难点攻克；前端基础建设和规范；前端团队建设和管理；</br>
      </div>
    </div>
    <div class="about-option">
      <div class="about-label">经验和积累</div>
      <div class="about-value">
        <ul>
          <li>大型项目的开发和团队管理</li>
          <li>ChatGPT服务搭建和对应应用开发</li>
          <li>分布式内网爬虫服务集群</li>
          <li>电商RPA工具设计和开发</li>
          <li>电商浏览器插件设计和开发</li>
          <li>业务组件库(@mgsz/components)</li>
          <li>
            方法库(@geyj/monitor-sdk、@geyj/web-utils)
          </li>
          <li>脚手架(@geyj/app-cli)</li>
          <li>技术文档(https://gyjtiancai.github.io/docs-front/)</li>
          <li>个人博客(http://blog.okoknb.cn)</li>
          <li>开源项目(https://github.com/gyjtiancai)</li>
          <li>亦云web端和桌面端：https://admin.effio.cn/pc/platform2/</li>
          <li>即时通讯</li>
          <li>大文件解析和上传</li>
          <li>表格在线配置工具</li>
          <li>自定义表单，自定义公式等</li>
        </ul>
      </div>
    </div>
    <div class="about-option">
      <div class="about-label">技能</div>
      <div class="about-value">
        <ul>
          <li>Web</li>
          <li>桌面</li>
          <li>小程序</li>
          <li>浏览器插件</li>
          <li>服务端</li>
          <li>脚手架</li>
          <li>爬虫</li>
          <li>RPA</li>
          <li>AI</li>
          <li>分布式内网服务集群搭建</li>
          <li>库封装和发布</li>
          <li>技术文档编写</li>
        </ul>
      </div>
    </div>
    <div class="about-option">
      <div class="about-label">技术栈</div>
      <div class="about-value">
        <ul>
          <li>HTML</li>
          <li>Css</li>
          <li>Javascript</li>
          <li>Typescript</li>
          <li>Vue2/Vue3</li>
          <li>React</li>
          <li>Electron</li>
          <li>Nodejs</li>
          <li>Webpack/Vite</li>
          <li>Acro Design/Element Plus等组件库</li>
        </ul>
      </div>
    </div>
    <div class="about-option">
      <div class="about-label">爱好</div>
      <div class="about-value">
        <ul>
          <li>唱歌</li>
          <li>旅行</li>
          <li>美食</li>
        </ul>
      </div>
    </div>
    <div class="about-option">
      <div class="about-label">想做的事</div>
      <div class="about-value">
        <ul>
          <li>开源 - 已实现</li>
          <li>学弹钢琴 - 已实现</li>
          <li>坐飞机 - 已实现</li>
          <li>去新疆</li>
          <li>机器学习 - 进行中</li>
          <li>项目管理 - 进行中</li>
        </ul>
      </div>
    </div>
    <div class="about-option">
      <div class="about-label">联系方式</div>
      <div class="about-value">
        <ul>
          <li>微信：yjyjyjyjyjgyj</li>
          <img src="@/assets/images/wx.png" />
          <li>QQ：961211746</li>
          <img src="@/assets/images/qq.png" />
          <li>邮箱：961211746@qq.com</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
export default {
  name: "AboutMe",
  components: {},
  data() {
    return {}
  },
  computed: {
    ...mapState([]),
    ...mapGetters([])
  },
  created() {},
  beforeDestroy() {},
  methods: {
    ...mapMutations([]),
    ...mapActions([])
  }
}
</script>

<style lang="scss" scoped>
.about-me {
  height: 100%;
  width: 100%;
  padding: 20px;
  overflow-y: auto;

  .about-option {
    margin-bottom: 20px;

    .about-label {
      color: #666;
      line-height: 1.4;
      font-weight: 700;
      font-size: 18px;
    }

    .about-value {
      margin-top: 12px;

      ul {
        margin-left: 20px;

        li {
          list-style: disc;
          font-size: 14px;
          margin-bottom: 6px;
        }
      }
    }
  }
}
</style>
